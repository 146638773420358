button, .btn {
    background: #2A913E;
    color: white;
    border: none;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.8rem;
    padding: 2px 36px;
    border-radius: 2px;
    line-height: 22px;
    text-decoration: none;
}

